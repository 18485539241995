<template>
    <div class="bbb">
        <van-nav-bar
        left-arrow
        title="药品能否统筹查询"
        @click-left="onClickLeft"
        />
        <div class="getdrug margin-auto" >
            <div class="Drugs_top DrugsInfo margin_top">
            <el-input placeholder="药品名、首字母" @input="debounce(1000)" v-model="search_value" >
                <template slot="append">
                <el-button icon="el-icon-full-screen"  @click="triggerFileInput">扫码</el-button>
                </template>
            </el-input>
            
            <div class="margin_top">
                <van-tag class=" margin_right" type="success">名字：地平片</van-tag>
                <van-tag type="info">名字拼音首字母：DPX</van-tag>
            </div>
            </div>
            <div class=" margin_top"  >
                <van-cell-group>
                    <van-cell v-for="(item,index) of DrugsList" value-class="flex-align-end">
                        <template slot="title">
                        <div class="flex-align Drugsname">
                            <span class="font-size3 bold">{{ item.name }}</span>
                            <van-tag v-if="item.class_ab !== '' && item.class_ab !== 'None'" type="success" single class="margin-left12">{{ item.class_ab }}</van-tag>
                        </div>
                        </template>
                        <template slot="label">
                        <span class="font-size0 van-color-text-assist">生产厂商：{{ item.manufacturingEnterprise  }}</span>
                        </template>
                        <template style="width: 30%;">
                            <span v-if="item.class_ab == '甲' || item.class_ab == '乙'" class="font-size2 van-color-success bold">可统筹</span>
                            <span v-else class="font-size2 van-color-warning bold">不可统筹</span>
                        </template>
                    </van-cell>
                </van-cell-group>
            </div>
            <div class="van-nodata" v-if="DrugsList.length == 0">
                <img class="van-nodata__figure" src="../assets/image/nocollection.png" alt="">
                <p class="van-nodata__title">查询药品是否可以统筹报销</p>
                <p class="van-nodata__tip">输入药品名字，或药品首字母或扫码查询</p>
            </div>
            <!-- <van-nodata v-if="DrugsList.length == 0" :figure="7" title="查询药品是否可以统筹报销" tip="输入药品名字，或药品首字母或扫码查询" /> -->
        
        </div>
        <footer_/>
    </div>
  </template>
  <script>
  import util from '../assets/js/util'
  import sha1 from 'js-sha1'
  import footer_ from '../components/footer.vue'
  import wx from '../assets/js/jweixin-1.6.0.js'
  export default {
    components: {
      footer_,
    },
    data() {
      return {
        search_value:'',
        DrugsList:[]
      }
    },
    methods: {
        triggerFileInput(){
            let that = this
            that.$api.article.getticket().then(res=>{
                    let fullUrl = window.location.href;
                    let url = fullUrl.split('#')[0];
                    let timestamp = new Date().getTime()
                    let sortStr = that.objKeySort({
                        jsapi_ticket:res.data.result.ticket,
                        noncestr:`${timestamp}`,
                        timestamp: Math.ceil(timestamp/1000),
                        url
                    })
                    wx.config({
                    // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: 'wx674869ee215ee6e7', // 必填，公众号的唯一标识
                    timestamp: Math.ceil(timestamp/1000), // 必填，生成签名的时间戳
                    nonceStr: `${timestamp}`, // 必填，生成签名的随机串
                    signature: sha1(sortStr),// 必填，签名
                    jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
                    });
                    wx.ready(function(res){
                    wx.scanQRCode({
                    needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        that.search_value = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
                        that.loadList()
                    }
                    })
            });
            })
        },
        objKeySort(arys) {
            //先用Object内置类的keys方法获取要排序对象的属性名数组，再利用Array的sort方法进行排序
            var newkey = Object.keys(arys).sort()
            console.log('newkey=' + newkey)
            var newObj = '' //创建一个新的对象，用于存放排好序的键值对
            for (var i = 0; i < newkey.length; i++) {
                //遍历newkey数组
                newObj += [newkey[i]] + '=' + arys[newkey[i]] + '&'
            }
            return newObj.substring(0, newObj.length - 1)
        },
        onClickLeft(){
            this.$router.back(-1)
        },
        isNumeric(str) {
        return /^\d+$/.test(str);
        },
        debounce(delay) {
            let that = this
            let timeoutId; // 定时器ID用于清除定时器
                clearTimeout(timeoutId); // 清除已有的定时器
                const context = this;
                const args = arguments;
                // 设置一个新的定时器，在指定的延迟时间后执行原函数
                timeoutId = setTimeout(function() {
                that.loadList()
                }, delay);
        },
        async loadList(){
            let key = 'summary'
            let type = '%'
            if(this.isNumeric(this.search_value)){
                if(this.search_value*1 >6900000000000 && this.search_value*1<7000000000000){
                    key = 'barCode'
                    type = '='
                }
            }
            let Params = {
            search_db: 'PublicDrugsMini',
            query_type: `limit|0-10`,
            no_count:'1'
          };

          let Data = {
            query: [
              {
                fild: { [key]: this.search_value },
                type: type,
                condition: '&&'
              },
            ],
          };

          const publicDrugsRes = await this.$api.article.query2(Params,Data);
          if(publicDrugsRes.data.result.length !==0){
            this.DrugsList = util.duplicateRemoval(publicDrugsRes.data.result,'name')
          }

        },

    },
    computed:{
        hasParameters(imageUrl){
        return function (url) {
        return /img\/(.+)/.test(url);
        };
        }
    },
    created() {
    //   this.loadList()
    },
    mounted(){
   

    }
  }
  </script>
  <style scopde lang="less">
    .getdrug{
        margin: auto 5px;
        height: calc(100vh - 200px);
        overflow-y: scroll;
    }
    .margin-auto{
        margin: auto 10px;
    }
    .van-error{
        width: 100%;
        height: 100%;
    }
    .margin-top{
        margin-top: 10px;
    }
    .van-cell__title{
        width: 65%;
    }
    .margin_right{
        margin-right: 10px;
    }
  </style>